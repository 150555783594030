import { queryValueIsString } from 'lib/routes/utils'
import { useRouter } from 'next/router'

type UseSisenseQueryStringResult =
  | {
      kind: 'invalid'
    }
  | {
      kind: 'valid'
      returnTo: string
      venueName: string
      venueId: string
    }

/**
 * Extracts the return_to and venue_id query params that Sisense passes to the
 * SSO remote login URL, which for us is expected to be /sisense/sso-handler.
 *
 * For more information see: https://sisense.dev/guides/accessSecurity/jwt/#actions-5
 */
export const useSisenseQueryString = (): UseSisenseQueryStringResult => {
  const router = useRouter()
  const returnTo = router.query['return_to']

  if (!queryValueIsString(returnTo)) {
    return { kind: 'invalid' }
  }

  const returnParams = new URLSearchParams(returnTo)
  const venueName = returnParams.get('venue_name')
  const venueId = returnParams.get('venue_id')

  if (!queryValueIsString(venueName) || !queryValueIsString(venueId)) {
    return { kind: 'invalid' }
  }

  return { kind: 'valid', returnTo, venueName, venueId }
}

import { useSisenseQueryString } from 'components/SisenseDashboardEmbed/useSisenseQueryString'
import { useSisenseAuthQuery } from 'lib/gql'
import { useRouter } from 'next/router'
import queryString from 'query-string'
import React, { useEffect } from 'react'

type SsoRedirectProps = {
  returnTo: string
  venueId: string
}

const JWT_URL = 'https://meandu.sisense.com/jwt'

const SsoRedirect = ({ returnTo, venueId }: SsoRedirectProps) => {
  const router = useRouter()

  const [{ data, fetching, error }] = useSisenseAuthQuery({
    variables: {
      venueId,
    },
  })

  useEffect(() => {
    if (!data?.sisenseAuth.token) {
      return
    }

    const token = data.sisenseAuth.token
    const queryParams = {
      jwt: token,
      return_to: returnTo,
    }
    const query = queryString.stringify(queryParams)
    const url = `${JWT_URL}?${query}`
    void router.replace(url)
  }, [router, data, returnTo])

  /**
   * TODO(SRV-2515): Style states
   */
  return (
    <>
      {fetching || (data && <div>Loading...</div>)}
      {error && <div>Error: {error.message}</div>}
    </>
  )
}

const SsoHandler = () => {
  const sisQueryString = useSisenseQueryString()

  /**
   * TODO(SRV-2515): Style states
   */
  return (
    <>
      {sisQueryString.kind === 'invalid' && <div>Invalid request</div>}
      {sisQueryString.kind === 'valid' && (
        <SsoRedirect
          returnTo={sisQueryString.returnTo}
          venueId={sisQueryString.venueId}
        />
      )}
    </>
  )
}

export default SsoHandler
